import React, { useState } from 'react';
import HighlightText from './HighlightText';
import Tooltip from './Tooltip';
import { formatDate } from './formatDate';
import '../index.css';

function getColor(score) {
  const colorArr = ["bg-red-950", "bg-red-800", "bg-orange-600", "bg-yellow-500"];

  const statRanges = {
    "CRITICAL": [9.0, 10.0],
    "HIGH": [7.0, 8.9],
    "MEDIUM": [4.0, 6.9],
    "LOW": [0.1, 3.9],
    "None": [0.0, 0.0],
  };

  let matchingStat = "None"; 
  let color = ""

  for (const stat of Object.keys(statRanges)) {
    const [min, max] = statRanges[stat];
    if (score >= min && score <= max) {
      matchingStat = stat;
      break; 
    }
  }

  color = colorArr[Object.keys(statRanges).indexOf(matchingStat)];


  return { color, stat: matchingStat };
}

function GridList(props) {

  const { vulnerabilities, filterValue, tags } = props;
  const itemsPerRow = 5; 
  const [next, setNext] = useState(itemsPerRow);

  const handleLoadMore = () => {
    setNext(next + itemsPerRow);
  };

  return (
    <>
      <div key={next} className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">

        {vulnerabilities.slice(0, next)?.map((vuln, vulni) => (
          <div key={vulni} className="bg-slate-900 flex flex-col text-white rounded-lg p-4 relative">

            <div className="border-b border-slate-700 pb-2">

              <h2 className="text-2xl font-semibold">{HighlightText(vuln.cve.id, filterValue, 'dark:bg-blue-500 dark:text-white')}</h2>

            </div>

		      {vuln.cve.PPoC ? (
		   <div className="mt-2">
                        <div className="container p-2 rounded-md bg-red-900">
                            <h3 className="text-white text-center text-lg font-bold"><span className="text-2xl">{'\u{2620}'}</span>Potential PoC in Wild
                                <span className="text-2xl">{'\u{2620}'}</span>
                            </h3>

                        </div>
		   </div>
                ) : null}


		<div className="pb-2 pt-2">
		   <p className="text-white text-lg font-bold"> {HighlightText(vuln.cve.sourceName, filterValue, 'dark:bg-blue-500 dark:text-white')} </p>
		</div>

	    <div className="pt-2">
			<p className="text-slate-600 font-bold text-left">Published</p>
			<p className="text-white font-bold text-left">{formatDate(vuln.cve.published)}</p>
                        <p className="text-slate-600 font-bold text-left">Last Modified</p>
                        <p className="text-white font-bold text-left">{formatDate(vuln.cve.lastModified)}</p>
	    </div>

		    <div className="">
            {vuln.cve.descriptions.map((desc, desci) => (
            <p key={desci} className="my-4">
            {HighlightText(desc.value, filterValue, 'dark:bg-blue-500 dark:text-white')}
                  </p>
              ))}
		</div>

		<div className="mt-auto">
              {vuln.cve.metrics.cvssMetricV3.map((cv, cvi) => (
		      <>
		<div className="flex">
		       <div className="grow h-12">
		      <p className="text-slate-600 pt-2 font-bold text-left">
		      		{cv.metricSourceName.length > 6 ? ( <> <Tooltip content={cv.metricSourceName}> {cv.metricSourceName.substring(0, 6)}... </Tooltip> </> ) : ( <> {cv.metricSourceName} 	</>)}
		      </p>
		      </div>

		      <div className="grow h-12">
                  <p key={cvi} className={`${getColor(cv.cvssData.baseScore).color} p-2 rounded-md text-center font-bold`}>


		      {cv.cvssData.baseSeverity === "CRITICAL" ? (
			      <>
                    {'\u{1F6A8}'}{cv.cvssData.baseSeverity} {cv.cvssData.baseScore}{'\u{1F6A8}'}
			      </>
		      )
			      : (
				      <>
				      {cv.cvssData.baseSeverity} {cv.cvssData.baseScore}
				      </>
			      )
		      }
                  </p>
		      </div>
			</div>
		      </>
              ))}
		</div>

        <div className="container z-10">
            <h3 className="text-lg font-bold mt-2">
		<a className="text-blue-500 underline" href={`https://nvd.nist.gov/vuln/detail/${vuln.cve.id}`} rel="noreferrer" target="_blank" >Reference </a>
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-white inline w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
</svg>
</h3>
        </div>



		{tags.length !== 0 && (
		<div className="container mt-10">
		<div className="flex flex-auto absolute bottom-4">
        {tags.map((tag, tagi) => (
              vuln.cve.sourceName.toLowerCase().includes(tag.value.toLowerCase()) ||
              vuln.cve.id.toLowerCase().includes(tag.value) ||
              vuln.cve.descriptions.some((description) => description.value.toLowerCase().includes(tag.value))

              ? (
              <span className={`${tag.color} inline-block align-middle p-4 mr-4 rounded-lg`}></span>
              ) : null

            ))}
		</div>
		</div>
	)}

            </div>

        ))}
      </div>

      {vulnerabilities.length > next && (
        <div className="container pt-10 px-10 mx-0 min-w-full flex flex-col items-center">
          <button className="bg-purple-900 text-white hover:bg-blue-400 align-middle p-4 mx-4 text-white rounded-lg" onClick={handleLoadMore}>Load More</button>
        </div>
      )}
    </>
  );
}

export default GridList;
