import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

const Tooltip = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block">
      <button
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        className="group w-full h-full sm:my-6 my-4 relative bottom-5"
      >
        {children}
      </button>
      <Transition
        show={isOpen}
        as={React.Fragment}
        enter="transition-opacity duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute z-20 -mt-6 -ml-1 bg-black text-white rounded px-2 py-1 text-sm">
          {content}
        </div>
      </Transition>
    </div>
  );
};

export default Tooltip;

