import React from 'react';
import Nav from './../components/Nav.js';
import './../index.css';

function Credits() {


	return (

		<>
		<Nav />
		<div className="max-w-2xl mx-auto p-4 text-center">
      <h1 className="text-2xl font-semibold text-white mb-4">Credits</h1>
		<h2 className="text-xl font-semibold m-4 text-white">Data</h2>

    		<p className="text-gray-700">
		This product uses data from the NVD API but is not endorsed or certified by the NVD.
		</p>

		<h2 className="text-xl font-semibold m-4 text-white">Logo</h2>

    		<p className="text-gray-700">
		Logo was created from Microsoft Bing's Image Creator | Powered by DALL-E 3 then modified manually.
		</p>

		<h2 className="text-xl font-semibold m-4 text-white">Code</h2>

    		<p className="text-gray-700">
		Site is powered by:
		</p>

		<ul className="text-gray-700">
			<li>ReactJS</li>
			<li>NodeJS</li>
			<li>Express</li>
			<li>TailwindCSS</li>
			<li>nginx</li>
			<li>Let's Encrypt</li>
		</ul>
		<p className="text-gray-700 p-2">
			And special thanks to ChatGPT 3.5 for answering my dumb questions.
		</p>
    </div>
		</>
	);
}

export default Credits;
