import React from 'react';

function GridLoading() {
  const numLoop = 5; 

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
	  {Array.from({ length: numLoop }, (_, index) => (
		  <div key={index} className="bg-slate-900 animate-pulse rounded-lg p-4 relative">
			<div className="bg-slate-900 border-spacing-1 shadow rounded-md p-4 max-w-sm w-full mx-auto">
			  <div className="animate-pulse flex space-x-4">
			    <div className="flex-1 space-y-6 py-1">
			      <div className="h-4 bg-slate-700 rounded"></div>
		  		<div className="grid grid-cols-3 gap-4">
			      <div className="h-2 bg-slate-700 col-span-2 rounded"></div>
		  		</div>
			      <div className="h-8"></div>
			      <div className="h-2 bg-slate-700 rounded"></div>
			      <div className="h-2 bg-slate-700 rounded"></div>
			      <div className="h-2 bg-slate-700 rounded"></div>
			      <div className="h-2 bg-slate-700 rounded"></div>
			      <div className="h-16"></div>
		  	      <div className="rounded-full bg-slate-700 h-10 w-30"></div>
			      </div>
			    </div>
			  </div>
			</div>
	  ))}

    </div>
  );
}

export default GridLoading;

