import React from 'react';

function HighlightText(text, filterValue, highlightClass) {
  if (!filterValue) {
    return text; // Return the original text if no filter value is provided
  }

  const parts = text.split(new RegExp(`(${filterValue})`, 'gi'));
  return (
    <>
      {parts.map((part, index) => (
        index % 2 === 0 ? (
          <span key={`${index}-${part}`}>{part}</span>
        ) : (
          <span key={`${index}-${part}`} className={highlightClass}>{part}</span>
        )
      ))}
    </>
  );
}

export default HighlightText;

