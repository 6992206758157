import React from 'react';
import Nav from './../components/Nav.js';
import '../index.css';

function Contact() {


	return (

		<>
		<Nav />
		<div className="max-w-2xl mx-auto text-center p-4">
      <h1 className="text-2xl font-semibold text-white mb-4">Contact</h1>
      <h2 className="text-xl font-semibold text-white mb-4">Email</h2>
    		<p className="text-gray-700">
			info [ at ] topcves [ dot ] com
		</p>
		
    </div>
		</>
	);
}

export default Contact;
