export function formatDate(getDate) {

const dateObject = new Date(getDate);
const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
const formattedDate = dateObject.toLocaleDateString(undefined, options);

return formattedDate;

}

