import React, { useEffect, useState } from 'react';
import GridList from './../components/GridList';
import GridLoading from './../components/GridLoading';
import Nav from './../components/Nav';
import './../index.css';

function ColorTags() {
  const colorArr = ["bg-blue-600", "bg-teal-700", "bg-emerald-500", "bg-green-900"];
  const randomIndex = Math.floor(Math.random() * colorArr.length);

  const randomColor = colorArr[randomIndex];

  const colorClass = `${randomColor}`;

  return colorClass;
}

function Home() {
  const [filteredVulnerabilities, setFilteredVulnerabilities] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isToggled, setIsToggled] = useState(false);

  const toggleSwitch = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    setLoading(true);
    fetch('https://proxy.topcves.com/main/?url=cve', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        const filteredVulnerabilities = data
          .filter((vulnerability) => {
            const cve = vulnerability.cve;
	      const matchesPPoC = cve.PPoC;

	   if(tags.length === 0) {
		  let matchesSource = "Unknown";

		   if (cve.sourceName) { 
            		matchesSource = cve.sourceName.toLowerCase().includes(filterValue) ;
		   }
            const matchesCveId = cve.id.toLowerCase().includes(filterValue);
            const matchesDescriptions = cve.descriptions.some((description) =>
              description.value.toLowerCase().includes(filterValue.toLowerCase())
            );
		   return isToggled
			      ? matchesPPoC
			      : matchesCveId || matchesDescriptions || matchesSource;

	   }
		  else{

           	    const tagsMatch = tags.some((tag) => {
			    return (
                 		cve.sourceName.toLowerCase().includes(tag.value.toLowerCase()) ||
				cve.id.toLowerCase().includes(tag.value) ||
				cve.descriptions.some((description) => description.value.toLowerCase().includes(tag.value)) 
			    )
             		});
			  return isToggled 
			  ? tagsMatch && matchesPPoC
			  : tagsMatch
		}
          })
          .sort((a, b) => {
            const scoreA = a.cve.metrics.cvssMetricV3[0]?.cvssData.baseScore || 0;
            const scoreB = b.cve.metrics.cvssMetricV3[0]?.cvssData.baseScore || 0;
            return scoreB - scoreA;
          });

        setFilteredVulnerabilities(filteredVulnerabilities);
	setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
	setLoading(false);
      });
  }, [tags, filterValue, isToggled]);

  const handleFilterChange = (event) => {
    setFilterValue(event.target.value.toLowerCase());
  };

  const handleKeyDown = (event) => {
      const colorTag = ColorTags();
      if (event.key === 'Enter' && filterValue.trim() !== '' && filterValue.length >= 3) {
	  if (!tags.some(tag => tag.value === filterValue)) {
	    setTags([...tags, { "value": filterValue, "color": colorTag }]);
	  }
	  setFilterValue('');
	}
  };

  const removeTag = (tag) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  return (
	  <>
	  <Nav filterValue={filterValue} handleFilterChange={handleFilterChange} handleKeyDown={handleKeyDown} isToggled={isToggled} toggleSwitch={toggleSwitch} />
      <div className="container mx-auto px-4 py-4">

          {tags.map((tag, index) => (
            <span key={index} className={`${tag.color} inline-block align-middle p-4 mb-4 mx-4 text-white text-center rounded-lg`}>
              {tag.value} <button onClick={() => removeTag(tag)}>&times;</button>
            </span>
          ))}
	  {loading ? ( 
		 <GridLoading />
        ) : (
          <GridList vulnerabilities={filteredVulnerabilities} filterValue={filterValue} tags={tags} />
        )}
      </div>
      </>
  );
}

export default Home;

