import React, { useEffect, useState } from 'react';
import { formatDate } from './formatDate';
import '../index.css';

function setColor(state, rel) {

	let statColor;

	switch(state) {
		case 'successful':
			statColor = rel === 'pre' ?
				'animate-[ping_3s_ease-in-out_infinite] bg-green-400'
				: 'bg-green-500';
			break;
		case 'failed':
			statColor = rel === 'pre' ?
				'bg-red-400'
				: 'bg-red-500';
			break;
		case 'syncing':
			statColor = 'bg-orange-';
			break;
 	  	default:
			statColor = '';
			break;
	}

	let statCss = rel === 'pre' ? 
		`relative inline-flex rounded-full h-3 w-3 ${statColor}` 
		: `absolute inline-flex h-full w-full rounded-full opacity-75 ${statColor}`;

	return statCss;
}

function Status() {

    const [loading, setLoading] = useState(true)
    const [getStatus, setStatus] = useState([]);

    useEffect(() => {
    setLoading(true);
    fetch('https://proxy.topcves.com/status/', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {

	      const getStatus = data;
	      setStatus(getStatus);
	      setLoading(false);

      })
    .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });


    },[]);

  return (
    <>

	  {loading ? null : (
	  getStatus.map((stat, statId) => (
		  <div className="pt-4">
<div className="bg-slate-950 text-slate-500 fixed bottom-0 w-full">

<div className="flex items-center justify-center ">
  <span key={statId} className="relative inline-flex">
    <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
      <span className={`${setColor(stat.status.state, 'pre')}`}></span>
      <span className={`${setColor(stat.status.state, '')}`}></span>
    </span>
  </span>
  <span className="inline-flex ml-4 pt-1 text-sm"> Last sync {stat.status.state} on {formatDate(stat.status.date)} at {stat.status.time} UTC. </span>
</div>
<div className="justify-center text-center">

</div>

		  </div>

</div>
	))
	  )}
    </>
  );
}

export default Status;

