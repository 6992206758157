import React from 'react';
import Nav from './../components/Nav.js';
import '../index.css';

function About() {


	return (

		<>
		<Nav />
		<div className="max-w-2xl mx-auto p-4 text-center">
      <h1 className="text-2xl font-semibold text-white mb-4">About</h1>
      <p className="text-gray-700">
        This site provides the latest information on the top Common Vulnerabilities and Exposures (CVEs) that have been flagged as Analyzed or Modified (post Undergoing Analysis and Pending) in the last 14 days. These CVEs are sorted by their severity score, with the highest scores at the top.
      </p>
		<div className="flex justify-center">
		     <div className="w-1/3 h-1/3">
			<img src="logo.png" alt="TpoCVEs Beetle Logo" />
		     </div>
		</div>
		<h2 className="text-xl font-semibold m-4 text-white">Origin</h2>

    <p className="text-gray-700">
	        This website was created out of a desire to simplify the process of searching for CVEs and staying up-to-date with potential poc and security risks in a more streamlined way.</p>

		<h2 className="text-xl font-semibold m-4 text-white">Usage</h2>
		<p className="text-gray-700">The search field allows you to search keywords that match description, source name, and CVE Ids while the toggle button searches for CVEs that may have a PPoc (Potential Proof of Concept) out in the wild. These are deemed more critical as expoure of how to exploit could be public.</p>
    </div>
		</>
	);
}

export default About;
