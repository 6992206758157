import React from 'react';
import Tooltip from './Tooltip.js';
import './../index.css';
import { NavLink, useLocation } from "react-router-dom";

function Nav(props) {

	const location = useLocation();

	const { filterValue, handleFilterChange, handleKeyDown, isToggled, toggleSwitch } = props;

	return (

<nav className="bg-slate-900 p-4 sticky top-0 border-b border-slate-700 z-50">
  <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">

		<NavLink
                      to="/">

		<div className="sm:pl-4 pl-0 w-full sm:w-auto flex items-center space-x-2">

		<div className="place-content-center h-20 w-20">
		<img src="logo.png" alt="logo" />
		</div>
    <h1 className="text-white text-4xl font-semibold inline">Top CVEs</h1>

		</div>

                    </NavLink>

		{location.pathname === "/" ? ( 
			<>

      <input
		type="text" 
		className="w-full sm:w-1/2 mt-4 sm:mt-0 rounded-lg bg-slate-700 px-4 py-2 text-white focus:outline-none" 
		placeholder="Search CVE Ids, Sources, Descriptions..." 
		maxLength={50}
		value={filterValue}
          	onChange={handleFilterChange}
	        onKeyDown={handleKeyDown}

	/>

			<div className="mt-8 mr-4">
<Tooltip content="Filter Potential PoCs">
	<div className="md:w-auto w-full pl-4 pt-4 place-content-center md:pt-0">
		 <div className="flex items-center space-x-2">
      <p className="text-white text-4xl">{'\u{2620}'}</p>
      <label className="flex cursor-pointer sm:ml-4">
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            checked={isToggled}
            onChange={toggleSwitch}
          />
          <div className={`block w-14 h-8 rounded-full ${isToggled ? 'bg-red-900' : 'bg-gray-600'}`}></div>
          <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${isToggled ? 'transform translate-x-full' : ''}`}></div>
        </div>
      </label>
    </div>
   </div>
</Tooltip>
			</div>
			</>
	) : null} 
      
		</div>
		</nav>
	);
}

export default Nav
