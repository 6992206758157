import { Outlet, NavLink, useLocation } from "react-router-dom";
import React from 'react';
import Status from '../components/Status';
import './../index.css';

function Layout() {
	const location = useLocation();

	  const routes = [
	    { name: "Home", link: "/" },
	    { name: "About", link: "/about" },
	    { name: "Credits", link: "/credits" },
	    { name: "Contact", link: "/contact" },
	  ];
		

	return (
		<>
		<div className="min-h-screen min-w-screen bg-gradient-to-r from-slate-950 bg-black">

		<Outlet />

		<footer className="text-white text-center py-6 w-full">
		      <div className="container mx-auto flex flex-col items-center">
			<nav id="footer">
		        <ul className="flex space-x-6 mb-4">
			{routes.map((route) => {
              if (route.link !== location.pathname) {
                return (
			<li key={route.link}>
                    <NavLink
                      to={route.link}
                      className="underline text-center"
                    >
                      {route.name}
                    </NavLink>
                  </li>
                );
              }
              return null;
            })}
		        </ul>
			</nav>
		        <p>&copy; {new Date().getFullYear()} TopCVEs.com</p>
		      </div>
		    </footer>
		 
		    <Status /> 

		</div>

		
		</>


	);
}

export default Layout
